export default {
  id: 'fr',
  common: {
    appTitle: 'SFPM NANCY',
    colon: ' : ',
    questionMark: ' ?',
    noResult: 'Aucun résultat',
    yes: 'Oui',
    no: 'Non',
    ok: 'OK',
    confirm: 'Valider',
    cancel: 'Annuler',
    close: 'Fermer',
    keep: 'Conserver',
    delete: 'Effacer',
    camera: 'Appareil photo',
    gallery: 'Galerie',
    chooseLang: 'Choix de la langue',
    addToFavorites: 'Ajouter aux favoris',
    addNote: 'Ajouter une note',
    editNote: 'Editer la note',
    defaultPlaceLabel: 'Plan',
    noNetworkTitle: 'Réseau indisponible',
    noNetwork: 'Nous vous prions de réessayer plus tard',
    loading: 'Veuillez patienter...',
    showAll: 'Afficher sur la carte',
    noPlacesToShowOnMap: 'Aucun emplacement à afficher sur la carte',
    termsOfUse: ' ',
    information: 'Informations',
    display: 'Affichage',
    switch2Columns: "Basculer sur un mode d'affichage adapté à la largeur de votre écran ? (conseillé)",
    share: 'Partager',
    goBack: 'Retour',
    goToHome: "Revenir à l'accueil",
    goToRootCategory: "Revenir à l'accueil de la rubrique",
    login: 'Identification',
    goToFavorites: 'Voir vos favoris',
    goToInbox: 'Accéder aux messages',
    goToSearch: 'Recherche de contenu',
    goToFilter: 'Filtrer le contenu',
    goToSynopticAgenda: 'Voir le synoptique',
    goBackToAgenda: 'Retour à la liste des événements',
    openMenu: 'Ouvrir le menu',
    relatedContent: 'Contenu lié',
    contributions: 'Contributions',
    feedback: 'Retour',
    rateThisSession: 'Évaluez cette session',
    seeMore: 'voir plus ...',
    seeLess: 'voir moins ...',
    accept: 'Accepter',
    deny: 'Refuser',
    continue: 'Continuer',
    noData: 'Aucune donnée disponible',
    navigationDenied: 'Vous devez être authentifié pour accéder à cette page',
    navigationDeniedwithoutLogin: "Vous n'avez pas le droit d'accéder à cette page",
    save: 'Enregistrer',
    saving: 'Enregistrement...',
    saved: 'Enregistré',
    invalidAuth: 'Session invalide ou expirée',
    updateFailed: "L'enregistrement a échoué",
    error: 'Une erreur est survenue',
    contact: 'Demande de contact',
    contacted: 'Contacté(e)',
    contactConfirmMessage: 'Une demande de contact en votre nom va être envoyée par courriel à cette personne. Si elle accepte, la personne vous répondra par courriel.',
    contactErrorTitle: 'Echec de la demande de contact',
    contactError: 'Nous vous prions de réessayer plus tard.',
    fetchError: 'Echec lors de la récupération des données',
    mandatory: ' <small><i> - obligatoire</i></small>',
    mandatoryInPlaceholder: ' (obligatoire)',
    emptyReadOnlyField: 'Non renseigné',
    smartbannerViewButton: 'VOIR',
    seeDetails: 'En savoir plus',
    seeAll: 'Voir tous',
    filterByCategory: 'Filtrer par catégorie',
    aboutCompany: "A propos de l'entreprise",
    contactScan: 'Analyse de contact',
    KlipsoLeads: 'Klipso Leads',
    KlipsoSso: 'Klipso SSO',
    OAuth2: 'Connectez-vous à B-Com',
    pdfBtnTitle: 'PDF'
  },
  formErrors: {
    missing: 'Valeur manquante',
    invalid: 'Valeur invalide'
  },
  location: {
    feature: 'Localisation',
    yourLocation: 'Votre position'
  },
  disclaimer: {
    text: 'Des cookies sont utilisés sur cette app pour effectuer des statistiques anonymes d’utilisation.'
  },
  share: {
    mail: 'Partager par email',
    facebook: 'Partager via Facebook',
    linkedin: 'Partager via LinkedIn',
    twitter: 'Partager via Twitter',
    positionShare: 'Position partagée',
    // favorite position share description
    shareFail: 'Echec du partage',
    linkedinShareDone: 'Page partagée sur votre profil LinkedIn',
    linkedinShareFail: 'Echec du partage via LinkedIn',
    facebookShareDone: 'Page partagée sur votre profil Facebook',
    facebookShareFail: 'Echec du partage via Facebook'
  },
  data: {
    gmap_places: {
      singular: 'Lieu',
      plural: 'Lieux',
      title: 'Lieux'
    },
    brands: {
      singular: 'Marque',
      plural: 'Marques',
      title: 'Marques'
    },
    brand_categories: {
      singular: 'Catégorie de marques',
      plural: 'Catégories de marques',
      title: 'Marques'
    },
    exhibitors: {
      singular: 'Exposant',
      plural: 'Exposants',
      title: 'Exposants',
      searchPlaceholder: 'Exposants'
    },
    exhibitor_categories: {
      singular: "Catégorie d'exposants",
      plural: "Catégories d'exposants",
      title: 'Exposants'
    },
    kleads_contacts: {
      singular: 'contact',
      plural: 'contacts'
    },
    restaurants: {
      singular: 'Restaurant',
      plural: 'Restaurants',
      title: 'Restaurants'
    },
    restaurant_categories: {
      singular: 'Catégorie de restaurants',
      plural: 'Catégories de restaurants',
      title: 'Restaurants'
    },
    monuments: {
      singular: 'À visiter',
      plural: 'À visiter',
      title: 'À visiter'
    },
    services: {
      singular: 'Informations',
      plural: 'Informations',
      title: 'Informations'
    },
    service_categories: {
      singular: 'Catégorie des informations',
      plural: 'Catégories des informations',
      title: 'Informations'
    },
    aircrafts: {
      singular: 'Aéronef',
      plural: 'Aéronefs',
      title: 'Aéronefs'
    },
    aircraft_categories: {
      singular: "Catégorie d'aéronefs",
      plural: "Catégories d'aéronefs",
      title: 'Aéronefs'
    },
    contacts: {
      singular: 'contact',
      plural: 'contacts'
    },
    events: {
      singular: 'Programme des js',
      plural: 'Programme des js',
      title: 'Programme des js',
      subevents: 'Interventions'
    },
    event_categories: {
      plural: 'Spécialité, Type, Programme',
      title: 'Programme des js'
    },
    conferences: {
      singular: 'Conférence',
      plural: 'Conférences'
    },
    speakers: {
      singular: 'Intervenant',
      plural: 'Intervenants',
      title: 'Intervenants',
      moderators: 'Modérateurs',
      orators: 'Orateurs'
    },
    papers: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    paper_categories: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    newproducts: {
      singular: 'Market Place',
      plural: 'Market Place',
      title: 'Market Place'
    },
    newproduct_categories: {
      singular: 'Market Place',
      plural: 'Market Place',
      title: 'Market Place'
    },
    goldensummits: {
      singular: "Sommet d'Or",
      plural: "Sommet d'Or",
      title: "Sommet d'Or"
    },
    goldensummit_categories: {
      singular: "Catégorie de sommet d'or",
      plural: "Catégories de sommet d'or",
      title: "Sommet d'Or"
    },
    userprojects: {
      singular: 'Projets Visiteurs',
      plural: 'Projets Visiteurs',
      title: 'Projets Visiteurs'
    },
    userproject_categories: {
      singular: 'Catégorie de projets visiteurs',
      plural: 'Catégories de projets visiteurs',
      title: 'Projets Visiteurs'
    },
    joboffers: {
      singular: "Offres d'emploi",
      plural: "Offres d'emploi",
      title: "Offres d'emploi"
    },
    joboffer_categories: {
      singular: "Catégorie d'Offres d'emploi",
      plural: "Catégories d'Offres d'emploi",
      title: "Offres d'emploi"
    },
    contests: {
      singular: 'Concours des animaux',
      plural: 'Concours des animaux',
      title: 'Concours des animaux'
    },
    contest_categories: {
      singular: 'Catégorie de concours des animaux',
      plural: 'Catégories de concours des animaux',
      title: 'Concours des animaux'
    },
    animations: {
      plural: 'Animations',
      title: 'Animations',
      singular: 'Animation',
      go: 'Voir sur le plan'
    },
    stores: {
      singular: 'Participant In The City',
      plural: 'Participants In The City',
      title: 'Participants In The City'
    },
    store_categories: {
      singular: 'Participant In The City',
      plural: 'Participants In The City',
      title: 'Participants In The City'
    },
    favoritepositions: {
      plural: 'Positions',
      singular: 'Position'
    },
    participants: {
      singular: 'Contacts',
      plural: 'Contacts',
      title: 'Contacts'
    },
    contacted_participants: {
      singular: 'Mes demandes de contact',
      plural: 'Mes demandes de contact',
      title: 'Mes demandes de contact'
    },
    meetingContacts: {
      singular: 'Mes rendez-vous',
      plural: 'Mes rendez-vous',
      title: 'Mes rendez-vous'
    },
    flights: {
      singular: 'Vol',
      plural: 'Vols'
    },
    flights_schedule: {
      locale: 'fr',
      // wtf ?
      longDateFormat: 'L HH:mm:ss',
      title: 'Agenda des vols',
      refresh: 'Rafraîchir',
      lastFetched: 'Dernière update',
      unknownAircraft: 'Aéronef inconnu',
      notScheduledYet: 'Horaire à venir',
      errorWhileFetching: 'Une erreur est survenue',
      lastRefreshed: 'Dernière mise à jour : ',
      noResults: 'Les vols ne sont pas encore programmés'
    },
    docunits: {
      singular: 'Casier presse',
      plural: 'Casiers presse'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' octets',
    kilobytes: ' ko',
    megabytes: ' mo'
  },
  update: {
    modalTitle: 'Mise à jour',
    update_detected: 'Mettre à jour les données ?',
    cancel_update: 'Annuler la mise à jour',
    update_done: 'Mise à jour terminée',
    new_version_detected: 'Nouvelle version détectée',
    timeout: 'Délai dépassé'
  },
  lang: {
    fr: 'français',
    en: 'anglais',
    zh: 'chinois'
  },
  menu: {
    restaurants: 'Restaurants',
    hours: 'Horaires',
    inbox: 'MESSAGES',
    brands: 'Marques',
    exhibitors: 'EXPOSANTS',
    exhibitorsAndNp: 'Exposants & produits',
    services: 'INFOS PRATIQUES',
    speakers: 'INTERVENANTS',
    mobigeo: 'PLAN DU PALAIS',
    googleMap: 'Google Map',
    stores: 'Participants In The City',
    agenda: 'Agenda',
    ebadge: 'Badges',
    klipsoLeads: 'Lecteur de badge Exposant',
    Contactscan: 'Lecteur de badge Visiteurs',
    experiences: 'Animations',
    update: 'Mettre à jour',
    language: 'Langue',
    news: 'Actualités',
    close: 'Fermer le menu',
    pmr: 'PMR',
    toggleProfile: 'Changer de profil',
    aircraftsAndFlights: 'Aéronefs & Vols',
    livewall: 'LiveWall',
    twitter: '#Santexpo',
    presskits: 'Casiers presse',
    chat: 'INTERACTIVITÉ',
    cgu: 'CGU',
    socialagenda: 'Programme Social',
    miseEnRelation: 'Mise en relation',
    contacts: 'Contacts',
    userData: 'Mon profil',
    gmap_places: 'Lieux',
    conferences: 'Conférences',
    marketplace: 'Market Place',
    goldensummit: "Sommet d'Or",
    animals: 'Carrefour Génétique',
    contests: 'Concours Animaux',
    jobOffers: "Offres d'emploi",
    userVisits: 'Visite à la ferme',
    userProjects: 'Projets Visiteurs',
    foodBeverage: 'Food & Beverage',
    weather: 'Rubrique Météo',
    programme: 'PROGRAMME DES JS',
    programmeSynoptic: 'Programme Synoptic',
    medias: 'RESEAUX SOCIAUX',
    videos: 'Videos',
    abstracts: 'ABSTRACTS'
  },
  home: {
    title: 'Accueil',
    myVisit: 'Ma visite',
    agenda: 'AGENDA',
    experiences: 'ANIMATIONS',
    exhibitorsAndProducts: 'EXPOSANTS & PRODUITS',
    mapsAndGroups: 'Plan',
    ebadge: 'BADGE',
    aircraftsAndFlights: 'AÉRONEFS & VOLS',
    pmr: 'PMR',
    papers: 'INTERACTIVITÉ',
    presskits: 'CASIERS PRESSE',
    socialagenda: 'PROGRAMME SOCIAL',
    speakers: 'INTERVENANTS',
    miseEnRelation: 'MISE EN RELATION',
    interactiviteAteliers: 'INTERACTIVITÉ ATELIERS',
    notifications: 'NOTIFICATIONS',
    videos: 'VIDÉOS',
    gmap_places: 'LIEUX',
    conferences: 'CONFÉRENCES',
    exhibitors: 'EXPOSANTS',
    mobigeo: 'PLAN DU PALAIS',
    googleMap: 'GOOGLE MAP',
    stores: 'PARTICIPANTS IN THE CITY',
    news: 'Actualités',
    marketplace: 'Market Place',
    goldensummit: "Sommet d'Or",
    animals: 'Carrefour Génétique',
    contests: 'Concours Animaux',
    jobOffers: "Offres d'emploi",
    userVisits: 'Visite à la ferme',
    userProjects: 'Projets Visiteurs',
    foodBeverage: 'Food & Beverage',
    weather: 'Rubrique Météo',
    programme: 'PROGRAMME DES JS',
    programmeSynoptic: 'Programme Synoptic',
    practicalInfo: 'INFOS PRATIQUES',
    medias: 'RESEAUX SOCIAUX',
    abstracts: 'ABSTRACTS'
  },
  profile: {
    title: 'Choix du profil',
    mainLabel: 'Sélectionnez votre profil',
    codeInput: "Saisissez le code dx'accès",
    invalidCode: "Code d'accès invalide",
    visitor: 'Visiteur',
    exhibitor: 'Exposant',
    default: 'Default',
    'webapp-exh': 'Webapp-exh',
    'webapp-evt': 'Webapp-evt',
    'webapp-spk': 'Webapp-spk'
  },
  exhibitor: {
    contacts: 'Contacts',
    relatedContacts: 'Contacts sur le salon',
    entities: 'Entités',
    agentCountries: 'Pays agents',
    brands: 'Marques',
    activities: 'Activités',
    categories: 'Catégories',
    metro: 'Métro',
    parcours: 'Parcours'
  },
  placeSearch: {
    editStart: 'Modifier départ',
    setDestination: 'Etape suivante',
    itinerary: 'Itinéraire',
    start: 'Choisissez votre point de départ',
    destination: 'Destination',
    poi: "Point d'intérêt"
  },
  aircraft: {
    pilot: 'Pilote',
    coPilot: 'Co-pilote',
    country: 'Pays',
    manufacturer: 'Constructeur',
    category: 'Catégorie',
    usage: 'Utilisation',
    wingSpan: 'Envergure',
    length: 'Longueur',
    emptyWeight: 'Tonnage',
    maxCruiseSpeed: 'Vitesse max. de croisière',
    characteristics: 'Caractéristiques',
    messageNoFlightYet: 'Cet aéronef est prévu dans un programme en vol. Information à venir ultérieurement'
  },
  newproducts: {
    launchDate: 'Mise sur le marché '
  },
  events: {
    to: 'à',
    access: 'Accès',
    publics: 'Public',
    category: 'Categorie'
  },
  mobigeo: {
    title: 'PLAN DU PALAIS',
    goTo: 'Y aller',
    shouldReload: 'Les données du plan ont été mises à jour, souhaitez-vous le recharger maintenant ?',
    reloadDueToUpdate: 'Mise à jour du plan…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'Aucun itinéraire trouvé.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: "Vous n'êtes pas localisé."
    },
    bluetoothWarning: 'Vous devez activer le bluetooth dans les paramètres de votre téléphone',
    locationSettingWarning: 'Vous devez activer le service de localisation dans les paramètres de votre téléphone',
    goSettingsQuestionTitle: 'Permissions requises',
    goSettingsQuestion: 'Voulez-vous accéder aux paramètres pour autoriser la localisation ?'
  },
  map: {
    title: 'Plan',
    goTo: 'Y aller',
    shouldReload: 'Les données du plan ont été mises à jour, souhaitez-vous le recharger maintenant ?',
    reloadDueToUpdate: 'Mise à jour du plan…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'Aucun itinéraire trouvé.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: "Vous n'êtes pas localisé."
    },
    bluetoothWarning: 'Vous devez activer le bluetooth dans les paramètres de votre téléphone',
    locationSettingWarning: 'Vous devez activer le service de localisation dans les paramètres de votre téléphone',
    goSettingsQuestionTitle: 'Permissions requises',
    goSettingsQuestion: 'Voulez-vous accéder aux paramètres pour autoriser la localisation ?'
  },
  googleMap: {
    title: 'Google Map',
    goTo: 'Y aller',
    shouldReload: 'Les données du plan ont été mises à jour, souhaitez-vous le recharger maintenant ?',
    reloadDueToUpdate: 'Mise à jour du plan…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'Aucun itinéraire trouvé.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: "Vous n'êtes pas localisé."
    },
    bluetoothWarning: 'Vous devez activer le bluetooth dans les paramètres de votre téléphone',
    locationSettingWarning: 'Vous devez activer le service de localisation dans les paramètres de votre téléphone',
    goSettingsQuestionTitle: 'Permissions requises',
    goSettingsQuestion: 'Voulez-vous accéder aux paramètres pour autoriser la localisation ?'
  },
  favorites: {
    title: 'FAVORIS',
    download: 'Télécharger (excel)',
    localNotif: 'Toggle Test Local Notif',
    modeSync: 'Changer le mode de Synchronisation',
    yourFavs: 'Vos favoris',
    other: 'Autres'
  },
  papers: {
    title: 'Abstracts'
  },
  agenda: {
    title: 'Agenda'
  },
  notes: {
    title: 'Note sur',
    pageTitle: 'Mes notes',
    pageLinkTitle: 'MES NOTES',
    delete: 'Supprimer la note',
    deleteAlert: 'Etes-vous certain de vouloir supprimer cette note ?',
    exportButton: 'Exporter mes notes',
    exportSuccess: 'Notes envoyées par mail',
    errors: {
      INVALID_CREDENTIALS: 'Accés refusé',
      INVALID_SMTP_CONFIGURATION: "Une erreur est survenue lors de l'envoi du mail",
      DEFAULT: "Une erreur est survenue lors de l'envoi du mail"
    },
    exportForm: {
      fields: {
        email: {
          label: 'Votre email',
          validationMessage: 'Veuillez entrer une adresse mail valide'
        }
      },
      title: 'Exportez vos notes  par mail',
      subtitle: 'Veuillez entrer votre adresse mail et cliquer sur le bouton envoyer pour recevoir vos notes'
    }
  },
  medias: {
    title: 'Réseaux sociaux',
    label: 'Suivez nos actualités sur'
  },
  pmr: {
    title: 'PMR'
  },
  search: {
    title: 'RECHERCHE',
    placeholderSuffix: '... (min. 2 car.)',
    submitLabel: 'Ok',
    searchFor: 'Rechercher des '
    //        keywords: 'Mots clés',
  },

  speaker: {
    relatedInterventions: 'Interventions'
  },
  hours: {
    title: "Horaires d'ouverture"
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: '',
  inbox: {
    title: 'Messages',
    empty: "Vous n'avez pas de message",
    dismiss: 'Ignorer',
    confirmNotif: 'OK'
  },
  login: {
    title: 'Identifiez-vous',
    fillTheFields: 'Merci de saisir votre identifiant et mot de passe avant de valider',
    badLogin: 'Votre identifiant ou mot de passe est incorrect',
    loginLabel: 'Identifiant',
    loginPlaceholder: 'Veuillez entrer votre identifiant',
    passwordLabel: 'Mot de passe',
    passwordPlaceholder: 'Veuillez entrer votre mot de passe',
    loginButton: 'Valider',
    loginSuccess: 'Vous êtes connecté',
    loginToSyncYourFavs: 'Entrez les mêmes identifiants créés lors de votre demande de badge pour synchroniser vos favoris entre la version web eurosatory.com et l’application mobile !',
    introductoryText: 'Vos login et mot de passe ont été envoyés à l’adresse email associée à votre inscription (la vôtre ou possiblement celle de la personne qui vous a inscrit)',
    forgottenPassword: "J'ai oublié mon mot de passe",
    createAccount: "Vous n'êtes pas encore inscrit ?",
    clichere: 'Cliquez ici',
    tos: "Conditions d'utilisation",
    profile: 'Profil',
    logout: 'Déconnexion',
    noNetwork: "Vous n'êtes pas connecté à Internet",
    loading: 'Vérification...',
    touMessage: 'En vous connectant, vous acceptez nos '
  },
  userData: {
    title: 'Profil',
    notice: 'Vos autorisations (un changement peut prendre quelques minutes pour être pris en compte).',
    fields: {
      profilePicture: {
        label: 'Photo de profile',
        placeholder: ''
      },
      title: {
        label: 'Civilité',
        placeholder: ''
      },
      firstName: {
        label: 'Prénom',
        placeholder: 'Veuillez entrer votre prénom'
      },
      lastName: {
        label: 'Nom',
        placeholder: 'Veuillez entrer votre nom'
      },
      organizationName: {
        label: 'Organisation',
        placeholder: 'Veuillez entrer votre organisation'
      },
      login: {
        label: 'Login',
        placeholder: 'Veuillez entrer votre login'
      },
      position: {
        label: 'Rôle',
        placeholder: 'Veuillez entrer votre rôle'
      },
      email: {
        label: 'Email',
        placeholder: 'Veuillez entrer votre email'
      },
      phone: {
        label: 'Téléphone',
        placeholder: 'Veuillez entrer votre téléphone'
      },
      visible: {
        label: 'Visible dans les contacts',
        placeholder: ''
      },
      contactable: {
        label: 'Contactable',
        placeholder: ''
      }
    },
    picture: {
      title: 'Photo de profil',
      selectYours: 'Sélectionnez votre photo',
      pickError: 'Erreur rencontrée lors du traitement de votre photo',
      confirmRemoval: 'Confirmez-vous la suppression de votre photo ?',
      confirmSubstitution: 'Vous allez remplacer votre photo de profil.<br>Confirmez-vous cette action ?',
      cropRequire: "Vous n'avez pas validé votre image"
    },
    updateSuccess: 'Sauvegarde effectuée',
    yourProfile: 'Votre profil',
    yourEvents: 'Vos conférences',
    contactedParticipantsSearchPlaceholder: 'Recherche de participants contactés',
    meetingContactsSearchPlaceholder: 'Recherche de rendez-vous',
    externalResources: 'Votre feuille de route',
    badge: 'Votre e-badge',
    userProjects: 'Projets Visiteurs',
    noNetwork: "Vous n'êtes pas connecté à Internet",
    metContacts: 'Contacts rencontrés',
    contactsSearchPlaceholder: 'Recherche parmi vos contacts',
    cantRemoveItemFromFavorites: 'Vous ne pouvez retirer des favoris un élément lié à votre profil.'
  },
  appointment: {
    button: 'Mise en relation',
    setProfileFirst: 'Vous devez renseigner votre profil pour pouvoir utiliser cette fonctionnalité.',
    sent: function sent(formatedDate, email, phone) {
      return "Demande envoy\xE9e ".concat(formatedDate, ". Votre destinataire vous r\xE9pondra par courriel \xE0 l\u2019adresse ").concat(email).concat(phone ? " ou par tel au ".concat(phone) : '', ".");
    },
    errors: {
      GENERIC: "Il n'a pas été possible d'envoyer la demande de mise en relation.",
      INVALID_REQUEST: "Une erreur est survenue lors de l'envoi de la demande de mise en relation.",
      EXHIBITOR_NOT_FOUND: "Une erreur est survenue lors de l'envoi de la demande de mise en relation.",
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: "Une erreur est survenue lors de l'envoi de la demande de mise en relation.",
      ALREADY_SENT: 'Une demande de mise en relation a déjà été envoyée pour cet exposant.'
    }
  },
  networking: {
    participantsFetchError: 'Erreur lors de la récupération des contacts'
  },
  synchroFavs: {
    codePlaceholder: 'Entrer un code',
    favoritesPageNoticeUnauthorizedMode: 'Vous pouvez synchroniser vos favoris entre vos différentes sessions web et app mobile. Pour cela cliquez sur',
    favoritesPageNoticeExtraUnauthorizedMode: 'Vos données de favoris ne sont ni exploitées pour quelque fin que ce soit, ni partagées avec un tiers. Vous pouvez arrêter la synchronisation à tout moment en appuyant sur le bouton',
    favoritesPageNotice: 'Quand vous êtes authentifié, vos favoris se synchronisent entre vos sessions web et mobile.',
    logoutConfirm: 'Vos favoris ne sont plus synchronisés sur ce terminal, voulez-vous les conserver en local ou les effacer ?',
    logoutConfirmUnauthorizedMode: 'La synchronisation sur ce terminal va être arrêtée. Voulez-vous effacer ou conserver vos favoris en local ?',
    status: {
      ongoing: 'Synchronisation des favoris en cours',
      failed: 'Vos favoris ne sont pas synchronisés. Vérifiez votre connexion de données',
      success: 'Vos favoris sont synchronisés'
    },
    haveCode: "J'ai un code",
    noCode: "je n'ai pas de code",
    syncCode: 'Code de synchronisation',
    localSyncCode: {
      singular: 'Code de synchronisation utilisé',
      plural: 'Codes de synchronisation utilisés'
    },
    enterCodeNotice: 'Entrez votre code pour synchroniser vos favoris',
    codeCreatedNotice: ' Le code ci-dessous vous a été attribué. Faites OK pour synchroniser vos favoris sur ce terminal',
    invalidCode: 'Code invalide',
    loadingCode: 'Chargement du code ...',
    comfirmDisableSynchro: 'Voulez vous arrêter la synchronisation de vos favoris ?',
    favoritesPageNoticeCodeSynchronised: 'Synchonisé avec le code',
    onlyDigits: 'Chiffres uniquement'
  },
  poll: {
    listTile: 'Sondages disponibles',
    noPolls: 'Aucun sondage disponible',
    sessionTitle: 'Session rating',
    sessionCodeError: 'CODE INVALIDE',
    fillTheFields: "Merci d'entrer votre code",
    code: 'code',
    enterCode: "Merci d'entrer le code de session",
    pleaseRate: "Merci d'évaluer",
    back: 'Retour',
    next: 'Suivant',
    commentPlaceholder: 'Votre commentaire',
    thank: 'Merci',
    submit: 'Soumettre',
    submitted: 'Déjà soumis',
    done: 'Terminé',
    noNetwork: 'Réseau indisponible. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.',
    willOpen: 'Ce sondage sera ouvert le',
    willClose: 'Ce sondage sera clos le',
    isClosed: 'Ce sondage est clos',
    requireAuthentication: 'Merci de vous authentifier.',
    started: 'Commencé',
    notStarted: 'Non commencé',
    serverError: 'Erreur Serveur. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.'
  },
  docunit: {
    documents: 'Documents',
    document: 'Document',
    contacts: 'Contacts',
    presskit: 'Casier presse'
  },
  filterDialog: {
    title: 'Filtrer',
    showResults: 'Voir les résultats',
    clear: 'Réinitialiser',
    found: {
      singular: ' trouvé',
      plural: ' trouvés'
    }
  },
  klipsoLeads: {
    title: 'Klipso Leads',
    androidScannerText: 'Placer le QR code dans le zone',
    scanError: 'Le scan a échoué',
    scanDenied: "Accès à l'appareil photo refusé.",
    disclaimer: "<p>En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :</p><ul><li>Son consentement est lié à son acceptation de se faire scanner son badge.</li><li>L'ensemble des données fournies lors de son inscription (coordonnées, activité, fonction, ..) à la manifestation sont susceptibles de vous être transmises (hors identifiants et mot de passe de connexion).</ul><p>Vous vous engagez à donner la possibilité de rectifier ou supprimer ses données dès que vous en aurez la possession, à chacun de vos contacts.</p><p>Vous vous engagez à respecter la confidentialité des données, de chacun de vos contacts, dès que vous en aurez la possession.</p>",
    accept: "J'ACCEPTE",
    validate: 'VALIDER',
    back: 'RETOUR',
    invalidLicence: 'Licence non valide',
    maxLicenceQuotaReached: "Votre quota d'utilisateurs est atteint",
    licenceScanAttemptOfCryptedBadge: 'Invalid licence QR-code',
    emptyLicenceOrUsername: "Veuillez saisir votre nom d'utilisateur et votre licence",
    lsEmptyLicenceAndUsername: "Veuillez saisir votre nom d'utilisateur et votre licence",
    lsEmptyLicence: 'Veuillez saisir votre licence',
    lsEmptyUsername: "Veuillez saisir votre nom d'utilisateur",
    alphanumericCharWarning: 'Seuls les caractères alphanumériques sont autorisés',
    licenceScreenFields: {
      licence: 'Clé de licence',
      userName: 'Indiquez vos NOM Prénom'
    },
    placeholders: {
      userName: 'NOM Prénom'
    },
    extendedMenuScreenFields: {
      licence: 'Clé de licence',
      userName: "Nom d'utilisateur"
    },
    allContactsSync: ' tous synchronisés',
    contactsNotSync: {
      singular: ' contact non synchronisé',
      plural: ' contacts non synchronisés'
    },
    sortByDate: 'Trier par date',
    sortByCompany: 'Trier par société',
    listEmpty: "Aucun contact pour l'instant",
    disabledScanButtonMessage: 'Le salon est terminé.',
    invalidVisitorBadge: 'Badge non valide',
    close: 'FERMER',
    cancel: 'ANNULER',
    save: 'ENREGISTRER',
    saveContactsError: function saveContactsError(count) {
      return count < 2 ? "".concat(count, " contact n'a pu \xEAtre sauvegard\xE9 :") : "".concat(count, " contacts n'ont pu \xEAtre sauvegard\xE9s :");
    },
    formTitle: 'QUESTIONNAIRE',
    formNotifMandatoryFieldsToSet: "Des champs obligatoires n'ont pas été saisis.",
    historyTitle: 'Historique',
    searchPlaceholder: 'Prénom, nom, société, email…',
    ongoingSync: 'Synchronisation en cours…',
    resetLicence: 'Réinitialiser / Changer de licence',
    resetLicenceConfirmTitle: 'ATTENTION  ⚠️',
    resetLicenceWithNonSyncContacts: 'Certains des contacts sont non synchronisés. Si vous appuyez sur OK, ils seront définitivement perdus.',
    resetLicenceConfirm: 'Si vous appuyez sur OK, Klipso Leads sera réinitialisé et les contacts scannés  stockés dans votre smartphone seront effacés. Ceux-ci sont cependant déjà sauvegardés dans votre espace web Exposant / Lecteur de Badges.<br>Si après réinitialisation, vous entrez de nouveau cette même licence, vous serez considéré comme un nouvel utilisateur et, si votre quota d’utilisateurs est atteint, vous serez rejeté.',
    exportContacts: 'Exporter mes contacts',
    tos: 'CGU',
    noAuthorization: "Votre quota d'utilisateurs est atteint"
  },
  contacts: {
    scanError: 'Le scan a échoué',
    scanDenied: "Accès à l'appareil photo refusé.",
    qrCodeUnHnadled: 'QRCode non reconnu',
    disclaimer: "<p>Ce lecteur de badge vous permet de scanner le QR-code présent sur le badge d'un autre participant pour pré-remplir une fiche contact que vous pourrez sauvegarder dans l'application (Mon Profil / onglet Mes Contacts) et exporter dans les contacts de votre smartphone.</p><p>Vous devez impérativement demander à la personne la permission de scanner son badge avant de le faire.</p><p>Les informations pré-remplies sont le nom, le prénom, l'organisation et la fonction de la personne (si disponibles). Pour obtenir une adresse email ou un téléphone, vous devrez le demander à la personne et l'ajouter dans le formulaire.</p><p>Avant de prendre une photo de la personne, vous devez impérativement lui demander l'autorisation de le faire</p>",
    disclaimerOk: "J'AI COMPRIS",
    disclaimerNOk: 'ANNULER',
    loginNeeded: 'L’usage du lecteur de badge nécessite que vous soyez authentifié',
    contactExist: 'Ce contact est déjà présent dans vos Contacts, voulez-vous le mettre à jour ?',
    photoPermission: 'Demandez l’autorisation à la personne avant de prendre son portrait',
    form: {
      title: 'Contact',
      subTitle: "Veuillez vérifier et compléter la fiche de votre contact avant de l'enregistrer. En particulier, demandez-lui son adresse email. Vous pouvez aussi le prendre en photo",
      fields: {
        profilePicture: {
          label: 'Photo de profile',
          validationMessage: '',
          alertMessage: "Vous n'avez pas validé votre photo de profil, êtes vous sûr de vouloir continuer ?"
        },
        firstName: {
          label: 'Prénom'
        },
        lastName: {
          label: 'Nom'
        },
        organizationName: {
          label: 'Organisation'
        },
        role: {
          label: 'Fonction'
        },
        email: {
          label: 'Email'
        },
        phone: {
          label: 'Tél. fixe'
        },
        mobilePhone: {
          label: 'Mobile'
        },
        address: {
          label: 'Adresse'
        }
      },
      imageValidation: ''
    }
  },
  searchTaiga: {
    title: 'Recherche - ',
    search: 'Rechercher',
    goToSimpleSearch: 'Retour à la recherche simple',
    goToAdvancedSearch: 'Recherche avancée',
    editSearch: 'Modifier votre recherche',
    emptyParameters: 'Merci de renseigner au moins un champ',
    fields: {
      search: {
        label: 'Recherche',
        placeholder: 'Nom, organisation,...'
      },
      profil: {
        label: 'Type de structure',
        placeholder: 'Indiquez les types de structure recherchées'
      },
      name: {
        label: 'Nom',
        placeholder: 'Entrer un nom'
      },
      company: {
        label: 'Société/Organisme',
        placeholder: 'Société/Organisme'
      },
      role: {
        label: 'Fonction',
        placeholder: 'Indiquez la fonction recherchée'
      },
      service: {
        label: 'Service/Pôle',
        placeholder: 'Indiquez le service recherché'
      },
      centerinterest: {
        label: "Centres d'intérêt",
        placeholder: "Indiquez les centres d'intérêt recherchés"
      }
    },
    fieldToggle: {},
    askAccuracy: 'Il y a plus de #number# résultats, veuillez affiner votre recherche',
    allCheckboxLabel: 'Tout sélectionner'
  },
  formModal: {
    cancel: 'Annuler',
    send: 'Envoyer'
  },
  permissions: {
    goSettingModalTitle: "L'accés à la caméra a été interdit",
    goSettingModalMessage: 'Voulez-vous modifier cela dans les paramètres ?',
    goSettingModalOk: 'Paramètres',
    goSettingModalCancel: 'Annuler'
  },
  modalPrivacyAgreement: {
    body: "<p>En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :</p",
    acceptButton: "Je suis d'accord",
    hint: 'Vous devez lire et accepter le contrat avant de continuer'
  },
  exportEvent: {
    exportSuccess: 'Événement exporté avec succès dans votre calendrier téléphonique',
    exportFail: "Échec de l'exportation de l'événement"
  },
  localNotif: {
    conf: 'La conférence  ',
    begin: ' va commencer dans',
    mins: 'Minutes',
    dismiss: 'Ignorer',
    confirmNotif: 'OK'
  }
};