import _defineProperty from "/Users/vagrant/git/mspot-sfpm2023/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _common, _flights_schedule;
export default {
  id: 'zh',
  common: (_common = {
    appTitle: 'Master',
    colon: ': ',
    questionMark: '?',
    noResult: '无任何结果',
    yes: '是',
    no: '否',
    ok: '确认',
    confirm: 'Confirm',
    cancel: '取消',
    close: 'Close',
    keep: 'Keep',
    delete: 'Delete',
    chooseLang: '选择语言',
    addToFavorites: 'Add to favorites',
    addNote: 'Add a note',
    editNote: 'Edit the note',
    defaultPlaceLabel: '地图',
    noNetworkTitle: '网络不可用',
    noNetwork: '请稍后再试',
    loading: '请稍候……',
    showAll: '在地图上显示',
    noPlacesToShowOnMap: '地图上无法显示任何相关位置',
    termsOfUse: '使用条款',
    information: '信息',
    contact: 'Contact',
    display: 'Display',
    switch2Columns: 'Switch to a page setup optimized for your screen width? (recommended)',
    share: 'Share',
    goBack: 'Go back',
    goToHome: 'Go to home',
    goToRootCategory: 'Go back to section home',
    goToSynopticAgenda: 'View synoptic',
    goBackToAgenda: 'Back to events list',
    login: 'Log in',
    goToFavorites: 'See your favorites',
    goToInbox: 'See messages',
    goToSearch: 'Go to search page',
    goToFilter: 'Filter content',
    openMenu: 'Open menu',
    seeMore: 'see more ...',
    seeLess: 'see less ...',
    accept: 'Accept',
    deny: 'Deny',
    continue: 'Continue',
    noData: 'No data available',
    navigationDenied: 'You must be authenticated to access this page.',
    navigationDeniedwithoutLogin: '您无权访问此页面',
    save: 'Save',
    saving: 'Saving...',
    saved: 'Saved',
    invalidAuth: 'Invalid or expired session',
    updateFailed: 'Update failed'
  }, _defineProperty(_common, "contact", 'Contact'), _defineProperty(_common, "contactedOn", 'Contacted on '), _defineProperty(_common, "contactConfirmMessage", 'A contact request will be sent by email to this person on your behalf. If he accepts the request, he will contact you back by mail or phone.'), _defineProperty(_common, "contactErrorTitle", 'Failed to contact participant'), _defineProperty(_common, "contactError", 'Please try again later.'), _defineProperty(_common, "smartbannerViewButton", 'DOWNLOAD'), _defineProperty(_common, "pdfBtnTitle", 'PDF'), _common),
  formErrors: {
    missing: 'Value is mandatory',
    invalid: 'Value is invalid'
  },
  location: {
    feature: 'Location',
    yourLocation: 'Your position'
  },
  disclaimer: {
    text: 'Cookies are used on this interactive map to compute anonymous usage statistics.'
  },
  share: {
    mail: 'Share by email',
    facebook: 'Share on Facebook',
    linkedin: 'Share on LinkedIn',
    twitter: 'Share on Twitter',
    positionShare: 'Position shared',
    // favorite position share description
    shareFail: 'Failed to share',
    linkedinShareDone: 'Page shared on your LinkedIn profile',
    linkedinShareFail: 'Failed to share on LinkedIn',
    facebookShareDone: 'Page shared on your Facebook profile',
    facebookShareFail: 'Failed to share on Facebook'
  },
  data: {
    brands: {
      singular: '品牌',
      plural: '品牌',
      title: '品牌'
    },
    brand_categories: {
      singular: '品牌分类',
      plural: '品牌分类',
      title: '品牌分类'
    },
    exhibitors: {
      singular: 'Exhibitor',
      plural: 'Exhibitors',
      title: 'Exhibitors'
    },
    exhibitor_categories: {
      singular: 'Exhibitor category',
      plural: 'Exhibitor categories',
      title: 'Exhibitors'
    },
    restaurants: {
      singular: '餐厅',
      plural: '餐厅',
      title: '餐厅'
    },
    restaurant_categories: {
      singular: '餐厅类别',
      plural: '餐厅类别',
      title: '餐厅'
    },
    monuments: {
      singular: '参观',
      plural: '参观',
      title: '参观'
    },
    services: {
      singular: '服务',
      plural: '服务',
      title: '服务'
    },
    service_categories: {
      singular: '服务类别',
      plural: '服务类别',
      title: '服务'
    },
    aircrafts: {
      singular: 'Aircraft',
      plural: 'Aircraft',
      title: 'Aircraft'
    },
    aircraft_categories: {
      singular: 'Aircraft category',
      plural: 'Aircraft categories',
      title: 'Aircraft'
    },
    events: {
      singular: 'Event',
      plural: 'Events',
      title: 'Events'
    },
    event_categories: {
      singular: 'Event category',
      plural: 'Event categories',
      title: 'Events'
    },
    speakers: {
      singular: 'Speaker',
      plural: 'Speakers',
      title: 'Speakers'
    },
    newproducts: {
      singular: 'Product',
      plural: 'Products',
      title: 'Products'
    },
    newproduct_categories: {
      singular: 'Product category',
      plural: 'Product categories',
      title: 'Products'
    },
    animations: {
      plural: 'Experiences',
      title: 'Experiences',
      singular: 'Experience',
      go: 'See on map'
    },
    favoritepositions: {
      plural: 'Locations',
      singular: 'Location'
    },
    flights: {
      singular: 'Flight',
      plural: 'Flights'
    },
    flights_schedule: (_flights_schedule = {
      locale: 'zh',
      // wtf ?
      longDateFormat: 'L h:mm:ss a',
      title: 'Flights Schedule',
      refresh: 'Refresh',
      lastRefreshed: 'Last update',
      unknownAircraft: 'Unknown aircraft',
      notScheduledYet: 'Not scheduled yet',
      errorWhileFetching: 'An error occurred'
    }, _defineProperty(_flights_schedule, "lastRefreshed", 'Last update : '), _defineProperty(_flights_schedule, "noResults", 'Flights are not scheduled yet'), _flights_schedule),
    infos: {
      singular: '营业时间'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' 字节',
    kilobytes: ' 千字节',
    megabytes: ' 兆字节'
  },
  update: {
    modalTitle: '更新',
    update_detected: '更新数据？',
    cancel_update: '取消更新',
    update_done: '更新已完成',
    new_version_detected: '检测到新版本',
    timeout: '已过期'
  },
  lang: {
    fr: 'french',
    en: 'english',
    zh: '官话'
  },
  menu: {
    restaurants: '餐厅',
    monuments: '参观',
    hours: '营业时间',
    inbox: '我的消息',
    brands: '品牌',
    exhibitors: 'Exhibitors',
    exhibitorsAndNp: 'Exhibitors & products',
    services: '服务',
    speakers: 'Speakers',
    map: '地图',
    agenda: 'Agenda',
    experiences: 'Experiences',
    update: '更新',
    language: '语言',
    close: '关闭菜单',
    pmr: 'PRM',
    toggleProfile: 'Change profile',
    aircraftsAndFlights: 'Aircraft & Flights',
    livewall: 'LiveWall',
    twitter: 'Twitter',
    location: 'Location',
    pmrQuestion: '你是一个行动不便的人吗？',
    pmrQuestion2: 'Itinerary for person with reduced mobility',
    cgu: 'Privacy policy',
    printania: 'Printania'
  },
  home: {
    title: 'Home',
    myVisit: 'My visit',
    agenda: 'Agenda',
    experiences: 'Experiences',
    exhibitors: 'Exhibitors',
    exhibitorsAndProducts: 'Exhibitors & Products',
    mapsAndGroups: 'Map & Groups',
    map: '地图',
    aircraftsAndFlights: 'Aircraft & Flights',
    pmr: 'PRM',
    practicalInfo: '服务',
    medias: 'Social Media',
    cgu: 'Privacy policy'
  },
  profile: {
    title: 'Choose profile',
    mainLabel: 'Select your profile',
    pro: 'PRO',
    gp: 'PUBLIC'
  },
  brand: {},
  exhibitor: {
    contacts: 'contacts',
    entities: 'entities',
    agentCountries: 'agent countries',
    brands: 'brands',
    activity: 'activities'
  },
  placeSearch: {
    editStart: 'Edit start',
    setDestination: 'Next',
    itinerary: 'Itinerary',
    start: 'Start',
    destination: 'Destination',
    poi: 'POI'
  },
  aircraft: {
    pilot: 'Pilot',
    coPilot: 'Copilot',
    country: 'Country',
    manufacturer: 'Manufacturer',
    category: 'Category',
    usage: 'Usage',
    wingSpan: 'Wing span',
    length: 'Length',
    emptyWeight: 'Empty weight',
    maxCruiseSpeed: 'Max.cruise speed',
    characteristics: 'Characteristics',
    messageNoFlightYet: 'This aircraft is planned for flying displays. More information to come.'
  },
  newproducts: {
    launchDate: 'Launch date'
  },
  events: {
    to: 'to',
    access: 'Access',
    publics: 'Public'
  },
  mobigeo: {
    title: '地图',
    goTo: '去那里',
    shouldReload: 'Map data has been updated, would you like to reload now?',
    reloadDueToUpdate: 'Map is updating…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'No itinerary found.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: 'You are not located.'
    },
    bluetoothWarning: 'You need to enable bluetooth in your device settings',
    locationSettingWarning: 'You need to enable location in your device settings',
    goSettingsQuestionTitle: 'Permissions required',
    goSettingsQuestion: 'Would you like to go to your device settings to allow location ?'
  },
  favorites: {
    title: '收藏'
  },
  notes: {
    title: 'Note about',
    pageTitle: 'My notes',
    pageLinkTitle: 'Notes',
    delete: 'Delete the note',
    deleteAlert: 'Are you sure that you want to delete this note ?',
    exportButton: 'Export my notes'
  },
  agenda: {
    title: 'Events'
  },
  medias: {
    title: 'Social Media',
    label: 'Follow our news on'
  },
  pmr: {
    title: 'PRM'
  },
  search: {
    title: '搜索',
    placeholderSuffix: '...',
    submitLabel: '确认',
    searchFor: 'Search for '
  },
  intersticiel: {
    closeLabel: '跳过广告'
  },
  promoMs: 'Powered by Mobile-Spot',
  inbox: {
    title: '我的消息',
    empty: '你没有消息',
    dismiss: '屏除',
    confirmNotif: '好的'
  },
  login: {
    title: 'Login',
    fillTheFields: 'Please fill in both fields before submitting',
    badLogin: 'The login or password you entered are incorrect',
    loginText: 'Login',
    passwordText: 'Password',
    loginButton: 'Login',
    loginSuccess: 'You are logged-in',
    loginToSyncYourFavs: 'Login to synchronize your favourites between eurosatory.com and the mobile app.',
    introductoryText: '',
    // introductoryText: 'You can now sync your favourites between the web eurosatory.com  and your smartphone & tablet',
    forgottenPassword: 'I forgot my password',
    createAccount: 'Create an account',
    tos: 'Terms Of Service',
    profile: 'Profile',
    logout: 'Logout',
    noNetwork: 'You are not connected to the Internet',
    loading: 'Loading...'
  },
  userData: {
    title: 'Profile',
    notice: 'Changes need an Internet connection, and take several minutes to be taken into account.',
    fields: {
      title: 'Civility',
      firstName: 'First Name',
      lastName: 'Last Name',
      organizationName: 'Organisation',
      position: 'Position',
      email: 'Email',
      phone: 'Phone',
      visible: 'Visible',
      contactable: 'Contactable'
    },
    selectYourPicture: 'Pick your portrait',
    updateSuccess: 'Profile updated',
    yourProfile: 'Your profile',
    yourEvents: 'Your interventions',
    externalResources: 'Your roadmap',
    noNetwork: 'You are not connected to the Internet'
  },
  appointment: {
    button: 'Appointment request',
    setProfileFirst: 'You must fill-in your profile to use this feature.',
    sent: function sent(formatedDate, email, phone) {
      return "Appointment request sent ".concat(formatedDate, ". Your recipient will answer you by email at ").concat(email).concat(phone ? " or by phone ".concat(phone) : '', ".");
    },
    errors: {
      GENERIC: 'Could not send the appointment request.',
      INVALID_REQUEST: 'An error occured while sending the appointment request.',
      EXHIBITOR_NOT_FOUND: 'An error occured while sending the appointment request.',
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: 'An error occured while sending the appointment request.',
      ALREADY_SENT: 'An appointment request has already been sent to this exhibitor.'
    }
  },
  synchroFavs: {
    logoutConfirm: 'Your favourites are no longer synchronized on this terminal. Do you want to keep them locally or delete them ?',
    status: {
      // hidden: '', no icon
      // disabled: '', show login modal
      ongoing: 'Synchronizing your favourites',
      failed: 'Your favourites are not synchronized, check your data connection',
      success: 'Your favourites are synchronized'
    }
  },
  docunit: {
    documents: 'Documents',
    document: 'Document',
    contacts: 'Contacts',
    presskit: 'Press kit'
  },
  filterDialog: {
    title: 'Filter',
    clear: 'Clear filter'
  },
  modalPrivacyAgreement: {
    body: '<p> 作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有有义务向您的联系人表明：作为本应用程序的用户，您有义务向您的联系人表明：作为本应用程序的用户，您有义务向您的联系人表明：作为本应用程序的用户应用程序，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明： 作为作为本应用程序的用户，您有义务向您的联系人 que 表明：作为本应用程序的用户，您有义务向您的联系人 q 表明ue：作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明： 作为本应用程序的用户，您有义务向您的联系人表明：作为此应用程序的用户，您有义务告诉您的联系人：</ p ',
    acceptButton: '我同意',
    hint: '您必须阅读并接受合同才能继续'
  },
  social: {
    titleBtn: '看视频'
  },
  pdfs: {
    title: '文件',
    titleBtn: 'pdf'
  },
  exportEvent: {
    exportSuccess: 'Event successfully exported in your phone calendar',
    exportFail: 'Failed to export event',
    alreadyExported: 'Event already exported in your phone calendar',
    requestFail: '无法导出事件，要授权它，您必须转到智能手机设置'
  },
  localNotif: {
    conf: 'The conference  ',
    begin: ' will start in',
    mins: 'Minutes',
    dismiss: 'Dismiss',
    confirmNotif: 'OK'
  },
  pdfSecured: {
    btnTitle: 'PDF',
    titleCodeRequired: 'Please enter the access code that is printed on the back of your badge',
    errorMessage: 'Failed to request pdf',
    serverError: 'Server Error. You can submit later.',
    noNetwork: 'Network unavailable. You can submit later.'
  },
  helpers: {
    update: {
      title: 'Helpers for Update',
      titleChooseDataType: 'Choose your dataType that you want to (update / delete)',
      titleUpdateBtn: 'Update',
      titleDeleteBtn: 'Delete',
      updateSuccess: 'Update completed',
      deleteSuccess: 'Deleted successfully'
    }
  }
};